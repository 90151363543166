/*** CSS for non-main pages ***/

$page-text-color: #171512;

body.page {
    font-family: "Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue","Helvetica","Arial",sans-serif;
    color: $page-text-color;
    display: flex;
    justify-content: center;
}

.pagewrapper {
    width: 85ch;
    max-width: 100%;
}

.content {
    margin-top: 10rem;
    margin-left: 5ch;
    margin-right: 5ch;
    max-width: 800px;

}

img.icon {
    height: 1rem;
}


header.page {
    display: flex;
    padding: 0;
    margin-top: 2em;
    width: 100%;

    h1 {
        font-size: 3rem;
        margin: 0;
    }
    .header-left {
        flex-grow: 0;
    }

    .header-right {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin-left: 2rem;
        i {
            font-size: 2rem;
        }
    }

    .header-icons {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-grow: 1;
        width: 100%;
        max-width: 15rem;
        a {
            color: black;
        }
        margin-top: 0.5rem;
    }
}

div.content {
    ul {
        li {
            // kramdown insists on wrapping any thing with HTML in it in <p>
            // tags for some reason, including single-line bullet points. This
            // adds a small amount of space and looks terrible, so we'll work
            // around it by effectively turning <p> tags into <span> tags.
            //
            // Remove this if you find a better solution.
            p:only-of-type {
                display: inline;
            }
        }
    }
}
