/** Base CSS values **/
body {
	font-family: 'Vollkorn', serif;
	color: $text-color;
	background-color: $background-color;
}

header,
main {
	padding: 0 20px;
}

/*** wrapper div for both header and main ***/
.wrapper {
	margin-top: 10%;
}

/*** anchor tags ***/
a:link,
a:visited,
a:hover,
a:active {
	color: $anchor-text-color;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}


.header-link {
  opacity: 0;
  color: $text-color;

  \-webkit-transition: opacity 0.2s ease-in-out 0.1s;
  \-moz-transition: opacity 0.2s ease-in-out 0.1s;
  \-ms-transition: opacity 0.2s ease-in-out 0.1s;

  a {
    color: $text-color;
  }
}

h2:hover .header-link,
h3:hover .header-link,
h4:hover .header-link,
h5:hover .header-link,
h6:hover .header-link {
  opacity: 1;
}



/*** main content list ***/
.main-list-item {
	font-weight: bold;
	font-size: 1.2em;
	margin: 0.8em 0;
}

/* override the left margin added by font awesome for the main content list,
since it must be aligned with the content */
.fa-ul.main-list {
	margin-left: 0;
}

/* list icons */
.main-list-item-icon {
	width: 36px;
	color: $text-color;
}

/*** logo ***/
.logo-container {
	text-align: center;
}

.logo {
	width: 96px;
	height: 96px;
	display: inline-block;
	background-size: cover;
	border-radius: 50%;
	-moz-border-radius: 50%;
	border: 2px solid $background-color;
	box-shadow: 0 0 0 3px $text-color;
}

/*** author ***/
.author-container h1 {
	font-size: 2.8em;
	margin-top: 0;
	margin-bottom: 0;
	text-align: center;
}

/*** tagline ***/
.tagline-container p {
	font-size: 1.3em;
	text-align: center;
	margin-bottom: 2em;
}

/******/
hr {
	border: 0;
	height: 1px;
	background-image: -webkit-linear-gradient(left, rgba(0,0,0,0), $text-color, rgba(0,0,0,0));
	background-image: -moz-linear-gradient(left, rgba(0,0,0,0), $text-color, rgba(0,0,0,0));
	background-image: -ms-linear-gradient(left, rgba(0,0,0,0), $text-color, rgba(0,0,0,0));
	background-image: -o-linear-gradient(left, rgba(0,0,0,0), $text-color, rgba(0,0,0,0));
}

/*** footer ***/
footer {
	position: fixed;
	bottom: 0;
	right: 0;
	height: 20px;
}

.poweredby {
	font-family: "Arial Narrow", Arial;
	font-size: 0.6em;
	line-height: 0.6em;
	padding: 0 5px;
}

/*** media queries ***/
/* X-Small devices (phones, 480px and up) */
@media (min-width: 480px) {
	/* wrapper stays 480px wide past 480px wide and is kept centered */
	.wrapper {
		width: 480px;
		margin: 10% auto 0 auto;
	}
}

/* All other devices (768px and up) */
@media (min-width: 768px) {
	/* past 768px the layout is changed and the wrapper has a fixed width of 680px
	to accomodate both the header column and the content column */
	.wrapper {
		width: 680px;
	}

	/* the header column stays left and has a dynamic width with all contents
	aligned right */
	header {
		float: left;
		width: 46%;
		text-align: right;
	}

	.author-container h1,
	.logo-container,
	.tagline-container p {
		text-align: right;
	}

	main {
		width: 46%;
		margin-left: 54%;
		padding: 0;
	}
}
