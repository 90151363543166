$text-color: #46433A;
$anchor-text-color: #CE534D;
$background-color: #e6e6ff;

@import 'base';
@import 'page';

/* Include fonts */
@import 'fonts/vollkorn.scss';

@import 'fonts/font-awesome/fontawesome.scss';
@import 'fonts/font-awesome/fa-brands.scss';
@import 'fonts/font-awesome/fa-regular.scss';
@import 'fonts/font-awesome/fa-solid.scss';
