$font_url: './fonts/webfonts/';

@font-face {
  font-family: 'Vollkorn';
  font-style: normal;
  font-weight: 400;
  src: local('Vollkorn Regular'),
       local('Vollkorn-Regular'),
       url($font_url + 'Vollkorn-Regular.woff2') format('woff2'),
       url($font_url + 'Vollkorn-Regular.woff') format('woff');
}

